import React, { createContext, useContext, useState } from "react";

// Create the context
const ScrollHandingContext = createContext();

export const ScrollHandingProvider = ({ children }) => {
  const [publicList, SetPublicList] = useState([]);
  const [timeStampForPublicList, SetTimeStampForPublicList] = useState("");
  const [lastPageForPublicList, SetLastPageForPublicList] = useState("");
  const [scrollPostionForPublicList, SetScrollPostionForPublic] = useState("");
  const [popularListCurrentPage, SetPopularListCurrentPage] = useState(0);

  const [privateList, SetPrrivateList] = useState([]);
  const [timeStampForPrivateList, SetTimeStampForPrivateList] = useState("");
  const [lastPageForPrivateList, SetLastPageForPrivateList] = useState("");
  const [scrollPostionForPrivateList, SetScrollPostionForPrivate] =useState("");
  const [privateListIndex, SetPrivateListIndex] = useState(0);

  const [privateListCurrentPage, SetPrivateListCurrentPage] = useState(1);

  const saveDataforPublicList = (list, time, lastpage) => {
    SetPublicList(list);
    SetTimeStampForPublicList(time);
    SetLastPageForPublicList(lastpage);
  };

  const resetDataforPublicList = () => {
    SetPublicList([]);
    SetTimeStampForPublicList("");
    SetLastPageForPublicList("");
    SetScrollPostionForPublic("");
  };
  const saveDataForScrollForPublic = (position) => {
    SetScrollPostionForPublic(position);
  };

  const saveDataforPrivateList = (list, time, lastpage) => {
    SetPrrivateList(list);
    SetTimeStampForPrivateList(time);
    SetLastPageForPrivateList(lastpage);
  };
  const resetDataforPrivateList = () => {
    SetPrrivateList([]);
    SetTimeStampForPrivateList("");
    SetLastPageForPrivateList("");
    SetScrollPostionForPrivate("");
    SetPrivateListIndex(0);
  };
  const saveDataForPrivateListIndex = (index,currentpage) => {
    SetPrivateListIndex(index);
    SetPrivateListCurrentPage(currentpage);
  };
  const saveDataForScrollForPrivate = (position) => {
    SetScrollPostionForPrivate(position);
  };

  const saveCurrentPageForPopularList=(currentpage) => {
    SetPopularListCurrentPage(currentpage);
  }


  const cleanUpScrollHandlingState=()=>{
    SetPublicList([]);
    SetPrrivateList([]);
    SetPrivateListIndex(0);
  }


  return (
    <ScrollHandingContext.Provider
      value={{
        saveDataforPublicList,
        resetDataforPublicList,
        timeStampForPublicList,
        publicList,
        lastPageForPublicList,
        scrollPostionForPublicList,
        saveDataForScrollForPublic,
        saveCurrentPageForPopularList,
        popularListCurrentPage,
        saveDataforPrivateList,
        resetDataforPrivateList,
        timeStampForPrivateList,
        privateList,
        lastPageForPrivateList,
        scrollPostionForPrivateList,
        privateListIndex,
        saveDataForPrivateListIndex,
        saveDataForScrollForPrivate,
        privateListCurrentPage,
        cleanUpScrollHandlingState
      }}
    >
      {children}
    </ScrollHandingContext.Provider>
  );
};

export const useScrollHandling = () => useContext(ScrollHandingContext);
