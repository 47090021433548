import React, { createContext, useContext, useState } from "react";

// Create the context
const RoutesHandingContext = createContext();

// Create the provider
export const RoutesHandingProvider = ({ children }) => {
  const [routes, setRoutes] = useState([]);
  const [userLoginInTencent,SetUserLoginInTencent]=useState(false);

  const UserLoginStatusInTencent=()=>{
    SetUserLoginInTencent(true);
  }

  // Function to add a new route
  const addRoute = (newRoute) => {
    setRoutes((prevRoutes) => [...prevRoutes, newRoute]);
  };

  // Function to remove a specific route by its path
  const removeRoute = (routePath) => {
    setRoutes((prevRoutes) => prevRoutes.filter((route) => route !== routePath));
  };

  // Function to clear all routes
  const clearRoutes = () => {
    setRoutes([]);
  };

  const hasRoute = (routePath) => {
    return routes.includes(routePath);
  };

  return (
    <RoutesHandingContext.Provider
      value={{ routes, addRoute, removeRoute, clearRoutes,hasRoute,UserLoginStatusInTencent,userLoginInTencent }}
    >
      {children}
    </RoutesHandingContext.Provider>
  );
};

// Hook to use the RoutesHandingContext
export const useRoutesHanding = () => useContext(RoutesHandingContext);
